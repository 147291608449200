@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.productItemCard {
    .productCard {
        display: flex;
        width: 100%;
        .productInfo {
            padding-left: 16px;
    
            .productTitle {
                font-size: 18px;
                font-weight: 600;
                color: #344054;
            }
    
            .subTitle {
                font-size: 16px;
                font-weight: 400;
                color: var(--greyscale-700, #323b49);
                margin-bottom: 8px;
            }
    
            .weight {
                margin-top: 16px;
                font-size: 16px;
                font-weight: 400;
                color: var(--greyscale-700, #323b49);
                margin-bottom: 8px;
            }
    
            .pack {
                font-size: 16px;
                font-weight: 400;
                color: var(--ltGreyscale600);
            }
        }
    }
}