.downloadAppCTA {
	padding-top: 30px;
	background: #ffffff;

	&_wrap {
		display: flex;
		justify-content: space-between;
		gap: 30px;

		.downloadAppCTA_left {
			width: 100%;
			max-width: 30%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			h2 {
				color: var(--ltGeyscale900);
			}
			p {
				&:first-of-type {
					color: var(--ltGreyscale600);
					margin-bottom: 30px;
				}
				&:nth-of-type(2) {
					font-size: 14px;
					font-weight: 600;
					margin-bottom: 16px;
				}
				&:nth-of-type(3) {
					font-size: 18px;
					font-weight: 600;
					margin-bottom: 20px;
				}
			}

			.download_btns {
				display: flex;
				align-items: center;
				gap: 24px;
				cursor: pointer;

				.btn_wrap {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 16px;
					border-radius: 12px;
					border: 1px solid var(--ltGreyscale300);
					background: var(--ltWhite);
					padding: 16px;

					.qrCode {
						width: 100%;
						height: 100%;
						max-width: 150px;
						max-height: 150px;
						aspect-ratio: 150 / 150;
					}
				}
				.appIcon {
					width: 100%;
					max-width: 150px;
					height: 100%;
					max-height: 45px;
				}
			}
		}

		.downloadAppCTA_right {
			width: 100%;
			max-width: 36%;
			display: flex;
			align-items: flex-end;
			margin-top: auto;
		}
	}
}
