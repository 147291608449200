.headerCarousel {
	width: 100%;
	margin-top: 30px;
	margin-bottom: 30px;
}
.headerCarousel_imgwrapper {
	position: relative;
	border-radius: 12px;
	overflow: hidden;
}
// .headerCarousel_imgwrapper_eplipses {
// 	position: absolute;
// 	bottom: 30px;
// 	left: 40%;
// 	display: flex;
// 	justify-content: center;
// 	gap: 1rem;
// 	align-items: center;
// }
.headerCarousel_image {
	position: relative;
	width: 100%;
	height: 100%;
	aspect-ratio: 1348 / 480;
	border-radius: 12px;
	overflow: hidden;
	cursor: pointer;
}
.bullet_wrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.headerCarousel_imgwrapper_img {
		height: 330px;
	}
}

@media (min-width: 1025px) and (max-width: 1200px) {
}
