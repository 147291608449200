.line {
	width: 100%;
	background: #eeeff2;
	height: 1px;
}
.line.dashed {
	background: #fff;
	border: 1px dashed #eeeff2;
}

.bgltSuccessDark {
	background: var(--ltSuccessDark);
}

.bgltGreyscale400 {
	background: var(--ltGreyscale400);
}
